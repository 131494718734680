import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-40598c2a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_2 = {
  style: {
    "display": "flex",
    "align-items": "center",
    "height": "66px"
  }
};
const _hoisted_3 = ["id"];
const _hoisted_4 = {
  class: "itemhead"
};
const _hoisted_5 = {
  key: 0,
  class: "danguan"
};
const _hoisted_6 = {
  class: "itemleft"
};
const _hoisted_7 = {
  class: "text"
};
const _hoisted_8 = {
  class: "text",
  style: {
    "height": "15px"
  }
};
const _hoisted_9 = {
  class: "itemmacth"
};
const _hoisted_10 = {
  class: "sup-text"
};
const _hoisted_11 = {
  key: 0,
  class: "corner-text"
};
const _hoisted_12 = {
  class: "listOdd"
};
const _hoisted_13 = {
  style: {
    "width": "30px",
    "display": "flex",
    "justify-content": "center",
    "align-items": "center"
  }
};
const _hoisted_14 = {
  style: {
    "width": "30px",
    "display": "flex",
    "justify-content": "center",
    "align-items": "center"
  }
};
const _hoisted_15 = {
  style: {
    "width": "30px",
    "display": "flex",
    "justify-content": "center",
    "align-items": "center"
  }
};
const _hoisted_16 = {
  class: "itemgoal"
};
const _hoisted_17 = {
  key: 0,
  class: "all"
};
const _hoisted_18 = {
  key: 0,
  class: "all"
};
const _hoisted_19 = {
  key: 0,
  class: "ban"
};
const _hoisted_20 = {
  class: "itemmacth"
};
const _hoisted_21 = {
  class: "sup-text"
};
const _hoisted_22 = {
  key: 0,
  class: "corner-text"
};
const _hoisted_23 = {
  class: "listOdd"
};
const _hoisted_24 = {
  style: {
    "width": "30px",
    "display": "flex",
    "justify-content": "center",
    "align-items": "center"
  }
};
const _hoisted_25 = {
  style: {
    "width": "30px",
    "display": "flex",
    "justify-content": "center",
    "align-items": "center"
  }
};
const _hoisted_26 = {
  style: {
    "width": "30px",
    "display": "flex",
    "justify-content": "center",
    "align-items": "center"
  }
};
const _hoisted_27 = {
  class: "textIcon"
};
const _hoisted_28 = {
  key: 0,
  class: "itemIcon"
};
const _hoisted_29 = {
  key: 1,
  class: "itemIcon"
};
const _hoisted_30 = {
  class: "itemcontent"
};
const _hoisted_31 = {
  class: "noteDiv"
};
const _hoisted_32 = {
  style: {
    "width": "95%"
  }
};
const _hoisted_33 = {
  class: "item"
};
const _hoisted_34 = {
  class: "item"
};
const _hoisted_35 = {
  class: "item"
};
const _hoisted_36 = {
  class: "item"
};
const _hoisted_37 = {
  class: "item"
};
const _hoisted_38 = {
  class: "item"
};
const _hoisted_39 = {
  class: "item"
};
const _hoisted_40 = {
  class: "item"
};
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import { showNotify } from '@nutui/nutui';
import AnalyzeView from './AnalyzeView.vue';
import AnalyzeList from './AnalyzeList.vue';
import AnalyzeTtgView from './AnalyzeTtgView.vue';
import AnalyzeBSView from './AnalyzeBSView.vue';
import AnalyzeHeatView from './AnalyzeHeatView.vue';
import AnalyzeMoney from './AnalyzeMoney.vue';
import HandicapView from './HandicapView.vue';
import AnalyzeStepLine from './AnalyzeStepLine.vue';
import KLineView from './KLineView.vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import service from '@/utils/request';
import request from '@/utils/request';
import { decryptData } from './calculation.js';
export default {
  __name: 'MacthList',
  setup(__props) {
    const timeNotify = msg => {
      showNotify.warn(msg, {
        duration: 2000
      });
    };
    const successNotify = msg => {
      showNotify.success(msg);
    };
    const dateCenter = ref(false);
    const currentDate = ref("");
    const selectDate = ref("");
    const dateRange = ref([]);
    const active = ref(3);
    const router = useRouter();
    const store = useStore();
    const fullUser = ref(store.state.fullUser);
    const value = ref([]);
    const loading = ref(false);
    const upPageNo = ref(2);
    const downPageNo = ref(2);
    const prePage = ref("0");
    const nextPage = ref("0");
    const leftRef = ref(null);
    const num = ref(-1);
    const oddNum = ref(0);
    const scrollTop = () => {
      if (leftRef.value) {
        //console.log('99', leftRef.value);
        const sortableEles = document.querySelectorAll('.var-collapse');
        //console.log(sortableEles);
        let itemHeight = 0;
        if (sortableEles.length > 0) {
          const firstListItem = sortableEles[0];
          itemHeight = firstListItem.offsetHeight;
          //console.log("第一个列表项的高度", itemHeight);
          //console.log('num.value', num.value);
        }
        nextTick(() => {
          leftRef.value.scrollTop = itemHeight * num.value;
        });
      }
    };
    const changeTabDate = val => {
      selectDate.value = dateRange.value[val].date;
      getMatchCalculatorV1();
    };
    const changeOdds = val => {
      oddNum.value = val;
    };
    const getCurrentDate = () => {
      // 使用Date对象获取当前日期  
      const now = new Date();
      // 手动构建YYYY-MM-DD格式的字符串  
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的，所以要+1，并使用padStart来确保总是两位数  
      const day = String(now.getDate()).padStart(2, '0'); // 同上，确保天数总是两位数  
      // 将年月日拼接成字符串  
      currentDate.value = `${year}-${month}-${day}`;
      selectDate.value = currentDate.value;
      getDateRange(currentDate.value);
    };
    const changeSelectDate = value => {
      selectDate.value = value;
      getDateRange(value);
      active.value = 3;
      dateCenter.value = false;
      getMatchCalculatorV1();
    };
    const getDateRange = targetDateString => {
      // 验证日期格式  
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateRegex.test(targetDateString)) {
        throw new Error('Invalid date format. Expected YYYY-MM-DD.');
      }

      // 将字符串日期转换为Date对象  
      const [year, month, day] = targetDateString.split('-').map(Number);
      const targetDate = new Date(year, month - 1, day); // 注意月份从0开始  

      // 创建日期范围数组  
      const list = [];
      for (let i = -2; i <= 4; i++) {
        // 创建一个新的Date对象，基于目标日期加减天数  
        const date = new Date(targetDate);
        date.setDate(date.getDate() + i);
        const weekdays = ['星期六', '星期日', '星期一', '星期二', '星期三', '星期四', '星期五'];
        const weekday = weekdays[date.getDay()];
        // 将Date对象转换为YYYY-MM-DD格式的字符串并添加到数组中  
        list.push({
          date: date.toISOString().split('T')[0],
          str: weekday
        });
      }
      dateRange.value = list;
    };
    const refreshClick = () => {
      subMatchList.value = [];
      upPageNo.value = 2;
      downPageNo.value = 2;
      prePage.value = "0";
      nextPage.value = "0";
      num.value = -1;
      getCurrentDate();
      getMatchCalculatorV1();
    };
    const refreshSubMatchList = async () => {
      loading.value = true;
      const response = await axios.get('https://webapi.sporttery.cn/gateway/jc/fb/getMatchDataPageListV1.qry?method=all&pageNo=' + upPageNo.value + '&pageType=0&pageSize=20');
      let data = response.data;
      let value = data.value;
      let matchInfoList = value.matchInfoList;
      let list = [];
      let n = 0;
      let ids = "";
      for (let i = 0; i < matchInfoList.length; i++) {
        for (let j = 0; j < matchInfoList[i].subMatchList.length; j++) {
          let subMatch = matchInfoList[i].subMatchList[j];
          subMatch.background = "background: #" + subMatch.backColor;
          let str = subMatch.matchDate.split("-");
          subMatch.monthDay = str[1] + "-" + str[2];
          subMatch.collapseState = false;
          subMatch.matchResultList = [];
          subMatch.note = {
            content: ""
          };
          subMatch.showMenu = false;
          ids = ids + subMatch.matchId + ",";
          n++;
        }
        list = list.concat(matchInfoList[i].subMatchList);
      }
      num.value = num.value + n;
      subMatchList.value = list.concat(subMatchList.value);
      upPageNo.value = upPageNo.value + 1;
      prePage.value = value.prePage;
      await umList();
      await getMatchRankInfo(ids);
      for (let i = 0; i < matchInfoList.length; i++) {
        await getOdds(matchInfoList[i].matchDate);
      }
      // nextPage.value = value.nextPage;
      loading.value = false;
    };
    const getSubMatchList = async () => {
      loading.value = true;
      const response = await axios.get('https://webapi.sporttery.cn/gateway/jc/fb/getMatchDataPageListV1.qry?method=all&pageNo=' + downPageNo.value + '&pageType=1&pageSize=20');
      let data = response.data;
      let value = data.value;
      let matchInfoList = value.matchInfoList;
      let list = [];
      let ids = "";
      for (let i = 0; i < matchInfoList.length; i++) {
        for (let j = 0; j < matchInfoList[i].subMatchList.length; j++) {
          let subMatch = matchInfoList[i].subMatchList[j];
          subMatch.background = "background: #" + subMatch.backColor;
          let str = subMatch.matchDate.split("-");
          subMatch.monthDay = str[1] + "-" + str[2];
          subMatch.collapseState = false;
          subMatch.matchResultList = [];
          subMatch.note = {
            content: ""
          };
          subMatch.showMenu = false;
          ids = ids + subMatch.matchId + ",";
        }
        list = list.concat(matchInfoList[i].subMatchList);
      }
      subMatchList.value = subMatchList.value.concat(list);
      downPageNo.value = downPageNo.value + 1;
      // prePage.value = value.prePage;
      nextPage.value = value.nextPage;
      await umList();
      await getMatchRankInfo(ids);
      for (let i = 0; i < matchInfoList.length; i++) {
        await getOdds(matchInfoList[i].matchDate);
      }
      loading.value = false;
    };
    const getMatchCalculatorV1 = async () => {
      loading.value = true;
      const response = await axios.get('https://webapi.sporttery.cn/gateway/jc/fb/getMatchDataPageListV1.qry?method=all&pageSize=999');
      let data = response.data;
      let value = data.value;
      let matchInfoList = value.matchInfoList;
      let list = [];
      let n = 0;
      let ids = "";
      for (let i = 0; i < matchInfoList.length; i++) {
        if (matchInfoList[i].matchDate == selectDate.value) {
          for (let j = 0; j < matchInfoList[i].subMatchList.length; j++) {
            n++;
            let subMatch = matchInfoList[i].subMatchList[j];
            subMatch.background = "background: #" + subMatch.backColor;
            let str = subMatch.matchDate.split("-");
            subMatch.monthDay = str[1] + "-" + str[2];
            subMatch.collapseState = false;
            subMatch.matchResultList = [];
            subMatch.note = {
              content: ""
            };
            subMatch.showMenu = false;
            if (num.value == -1 && subMatch.matchStatusName != '已完成') {
              if (n != 0) {
                num.value = n - 1;
              } else {
                num.value = n;
              }
            }
            ids = ids + subMatch.matchId + ",";
          }
          list = list.concat(matchInfoList[i].subMatchList);
        }
      }
      if (matchInfoList.length == 0) {
        num.value = 0;
      }
      subMatchList.value = list;
      loading.value = false;
      prePage.value = value.prePage;
      nextPage.value = value.nextPage;
      await getMatchRankInfo(ids);
      await getMatchLiveV1();
      //await scrollTop();
      await umList();
      await collectList();
      for (let i = 0; i < matchInfoList.length; i++) {
        if (matchInfoList[i].matchDate == selectDate.value) {
          await getOdds(matchInfoList[i].matchDate);
        }
      }
    };
    const getMatchLiveV1 = async () => {
      const response = await axios.get('https://webapi.sporttery.cn/gateway/jc/fb/getMatchLiveV1.qry?matchIds=&eventTc=goals,penalty_shootout');
      let data = response.data;
      let value = data.value;
      if (value != null && value.length > 0) {
        let list = subMatchList.value;
        for (let i = 0; i < list.length; i++) {
          for (let j = 0; j < value.length; j++) {
            if (list[i].matchId == value[j].matchId) {
              list[i].sectionsNo999 = value[j].sectionsNo999;
              list[i].sectionsNo1 = value[j].sectionsNo1;
              if (value[j].matchMinute == '') {
                list[i].matchStatusName = value[j].matchStatusName;
              } else {
                list[i].matchStatusName = value[j].matchMinute;
              }
            }
          }
        }
        subMatchList.value = list;
      }
      await request.get('/getInfo').then(res => {
        if (res.code == 200) {
          // 登录成功，更新Vuex状态
          store.dispatch('login', res);
        }
      });
      // 使用Date对象获取当前日期  
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的，所以要+1，并使用padStart来确保总是两位数  
      const day = String(now.getDate()).padStart(2, '0'); // 同上，确保天数总是两位数  
      const dateStr = `${year}-${month}-${day}`;
      if (selectDate.value == dateStr) {
        await getOdds();
      }
    };

    /**
     * 折叠面板触发
     * @param {*} val 
     */
    const handleChange = async val => {
      let list = subMatchList.value;
      for (let j = 0; j < list.length; j++) {
        let subMatch = list[j];
        if (val.includes(subMatch.matchId)) {
          subMatch.collapseState = true;
          // if (subMatch.matchStatusName == '已完成' && subMatch.matchResultList.length == 0) {
          //   await getMatchGeneral(subMatch.matchId);
          // }
        } else {
          subMatch.collapseState = false;
        }
      }
    };

    /**
     * 获取赛果
     * @param {赛事id} matchId 
     */
    const getMatchGeneral = async matchId => {
      const response = await axios.get('https://webapi.sporttery.cn/gateway/jc/fb/getMatchGeneral.qry?matchId=' + matchId + '&matchStatus=11');
      let data = response.data;
      let value = data.value;
      let matchResultList = value.matchResultList;
      let list = subMatchList.value;
      for (let j = 0; j < list.length; j++) {
        let subMatch = list[j];
        if (matchId == subMatch.matchId) {
          subMatch.matchResultList = matchResultList;
        }
      }
    };
    const getMatchRankInfo = async matchIds => {
      if (matchIds != '') {
        const response = await axios.get('https://tuhaojie.asia/api-foot/matchGoalWater/getInfo?matchIds=' + matchIds);
        let data = decryptData(response.data);
        let list = subMatchList.value;
        for (let j = 0; j < list.length; j++) {
          let subMatch = list[j];
          for (let i = 0; i < data.length; i++) {
            let MatchOdd = data[i];
            if (subMatch.matchId == MatchOdd.matchId) {
              subMatch.homeRank = MatchOdd.homeRank;
              subMatch.awayRank = MatchOdd.awayRank;
              subMatch.singleHad = MatchOdd.singleHad;
            }
          }
        }
      }
    };

    /**
     * 查询笔记
     */
    const umList = async () => {
      let list = subMatchList.value;
      let matchIds = [];
      for (let j = 0; j < list.length; j++) {
        let subMatch = list[j];
        matchIds = matchIds.concat(subMatch.matchId);
      }
      const response = await service.get('/system/note/umList', {
        matchIds: matchIds
      });
      if (response.code == 200) {
        let rows = response.rows;
        for (let j = 0; j < list.length; j++) {
          let subMatch = list[j];
          for (let i = 0; i < rows.length; i++) {
            if (rows[i].matchId == subMatch.matchId) {
              subMatch.note = rows[i];
            }
          }
        }
      } else {
        timeNotify(response.msg);
      }
    };

    /**
     * 笔记保存
     * @param {*} event 
     */
    const noteSave = async (matchId, note) => {
      console.log(matchId, note);
      note.matchId = matchId;
      // 发送登录请求
      const response = await service.post('/system/note/save', note);
      if (response.code == 200) {
        //假设响应中包含一个token字段，我们将其保存到localStorage中
        successNotify("保存成功");
        await umList();
      } else {
        timeNotify(response.msg);
      }
    };
    function closeMenu(data, menu) {
      let list = subMatchList.value;
      let json = {
        collectId: menu.collectId,
        matchId: data.matchId
      };
      request.post('/system/userCollect/add', json).then(res => {
        if (res.code == 200) {
          successNotify("已收藏");
          for (let j = 0; j < list.length; j++) {
            let subMatch = list[j];
            if (subMatch.matchId == data.matchId) {
              subMatch.showMenu = false;
            }
          }
        }
      });
    }

    /**
     * 足彩网获取当天的盘口数据
     */
    const getOdds = async date => {
      let url = 'https://tuhaojie.asia/api-foot/matchGoalWater/selectOdds';
      if (date != null && date != '') {
        url = 'https://tuhaojie.asia/api-foot/matchGoalWater/selectOdds?date=' + date;
      }
      let list = subMatchList.value;
      const response = await axios.get(url);
      const data = response.data;
      for (let j = 0; j < data.length; j++) {
        let odd = data[j];
        for (let i = 0; i < list.length; i++) {
          let subMatch = list[i];
          if (odd.cc_ID == subMatch.matchNumStr) {
            subMatch.listOdds = odd.listOdds;
            subMatch.oddId = odd.id;
          }
        }
      }
    };
    const menuList = ref([]);
    const collectList = () => {
      request.get('/system/collect/list').then(res => {
        menuList.value = res;
      });
    };
    const subMatchList = ref([]);
    const modalRef = ref(null);
    const clickInfo = matchId => {
      modalRef.value.show(matchId);
    };
    const modalHeatRef = ref(null);
    const clickHeat = matchId => {
      modalHeatRef.value.show(matchId);
    };
    const modalListRef = ref(null);
    const clickList = matchId => {
      modalListRef.value.show(matchId);
    };
    const modalTtgRef = ref(null);
    const clickTtg = matchId => {
      modalTtgRef.value.show(matchId);
    };
    const modalBSRef = ref(null);
    const clickBS = matchId => {
      modalBSRef.value.show(matchId);
    };
    const modalMoneyRef = ref(null);
    const clickMoney = matchId => {
      modalMoneyRef.value.show(matchId);
    };
    const handicapRef = ref(null);
    const clickHandicap = matchId => {
      handicapRef.value.show(matchId);
    };
    const stepLineRef = ref(null);
    const clickStepLine = matchId => {
      stepLineRef.value.show(matchId);
    };
    const kLineRef = ref(null);
    const clickKLine = (id, matchId) => {
      kLineRef.value.show(id, matchId);
    };
    const toMatchInfo = matchId => {
      router.push({
        name: 'MatchInfo',
        params: {
          matchId: matchId
        }
      });
    };
    let intervalId;
    onMounted(async () => {
      await getCurrentDate();
      await getMatchCalculatorV1();
      // 在组件挂载后开始调用接口
      intervalId = setInterval(getMatchLiveV1, 30000); // 每隔30秒调用一次
    });
    onUnmounted(() => {
      // 在组件卸载时清除定时器
      clearInterval(intervalId);
    });
    return (_ctx, _cache) => {
      const _component_var_tab = _resolveComponent("var-tab");
      const _component_var_tabs = _resolveComponent("var-tabs");
      const _component_var_col = _resolveComponent("var-col");
      const _component_var_icon = _resolveComponent("var-icon");
      const _component_var_row = _resolveComponent("var-row");
      const _component_var_date_picker = _resolveComponent("var-date-picker");
      const _component_var_popup = _resolveComponent("var-popup");
      const _component_var_button = _resolveComponent("var-button");
      const _component_var_cell = _resolveComponent("var-cell");
      const _component_var_menu = _resolveComponent("var-menu");
      const _component_var_input = _resolveComponent("var-input");
      const _component_var_collapse_item = _resolveComponent("var-collapse-item");
      const _component_var_collapse = _resolveComponent("var-collapse");
      const _component_var_loading = _resolveComponent("var-loading");
      const _component_var_fab = _resolveComponent("var-fab");
      return _openBlock(), _createElementBlock("div", {
        ref_key: "leftRef",
        ref: leftRef
      }, [_createVNode(AnalyzeHeatView, {
        ref_key: "modalHeatRef",
        ref: modalHeatRef
      }, null, 512), _createVNode(AnalyzeView, {
        ref_key: "modalRef",
        ref: modalRef
      }, null, 512), _createVNode(AnalyzeList, {
        ref_key: "modalListRef",
        ref: modalListRef
      }, null, 512), _createVNode(AnalyzeTtgView, {
        ref_key: "modalTtgRef",
        ref: modalTtgRef
      }, null, 512), _createVNode(AnalyzeBSView, {
        ref_key: "modalBSRef",
        ref: modalBSRef
      }, null, 512), _createVNode(AnalyzeMoney, {
        ref_key: "modalMoneyRef",
        ref: modalMoneyRef
      }, null, 512), _createVNode(HandicapView, {
        ref_key: "handicapRef",
        ref: handicapRef
      }, null, 512), _createVNode(AnalyzeStepLine, {
        ref_key: "stepLineRef",
        ref: stepLineRef
      }, null, 512), _createVNode(KLineView, {
        ref_key: "kLineRef",
        ref: kLineRef
      }, null, 512), _createVNode(_component_var_row, null, {
        default: _withCtx(() => [_createVNode(_component_var_col, {
          span: 22
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_var_tabs, {
            active: active.value,
            "onUpdate:active": _cache[0] || (_cache[0] = $event => active.value = $event),
            onChange: changeTabDate,
            "item-direction": "vertical"
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dateRange.value, (item, index) => {
              return _openBlock(), _createBlock(_component_var_tab, {
                key: index
              }, {
                default: _withCtx(() => [_createElementVNode("div", null, _toDisplayString(item.date.split('-')[1]) + "-" + _toDisplayString(item.date.split('-')[2]), 1), _createElementVNode("div", null, _toDisplayString(item.str), 1)]),
                _: 2
              }, 1024);
            }), 128))]),
            _: 1
          }, 8, ["active"])])]),
          _: 1
        }), _createVNode(_component_var_col, {
          span: 2
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_var_icon, {
            name: "calendar-month",
            size: 26,
            onClick: _cache[1] || (_cache[1] = $event => dateCenter.value = true)
          })])]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_var_popup, {
        show: dateCenter.value,
        "onUpdate:show": _cache[3] || (_cache[3] = $event => dateCenter.value = $event),
        style: {
          "width": "80%"
        }
      }, {
        default: _withCtx(() => [_createVNode(_component_var_date_picker, {
          modelValue: selectDate.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => selectDate.value = $event),
          onChange: changeSelectDate
        }, null, 8, ["modelValue"])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_var_loading, {
        description: "加载中",
        loading: loading.value
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(subMatchList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_var_collapse, {
            modelValue: value.value,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => value.value = $event),
            offset: false,
            onChange: handleChange,
            key: index
          }, {
            default: _withCtx(() => [_createVNode(_component_var_collapse_item, {
              name: item.matchId
            }, {
              title: _withCtx(() => [_createElementVNode("div", {
                style: {
                  "width": "330px"
                },
                id: item.matchId
              }, [_createVNode(_component_var_row, null, {
                default: _withCtx(() => [_createVNode(_component_var_col, {
                  span: 12,
                  offset: 12
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [item.singleHad ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(item.matchNumStr), 1)) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                  }, [_createTextVNode(_toDisplayString(item.matchNumStr), 1)], 64))])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 4
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_var_row, null, {
                    default: _withCtx(() => [_createVNode(_component_var_col, {
                      span: 24
                    }, {
                      default: _withCtx(() => [_createElementVNode("div", {
                        class: "tag",
                        style: _normalizeStyle(item.background)
                      }, _toDisplayString(item.leagueAbbName), 5)]),
                      _: 2
                    }, 1024)]),
                    _: 2
                  }, 1024), _createVNode(_component_var_row, null, {
                    default: _withCtx(() => [_createVNode(_component_var_col, {
                      span: 24
                    }, {
                      default: _withCtx(() => [_createElementVNode("div", _hoisted_7, _toDisplayString(item.monthDay), 1)]),
                      _: 2
                    }, 1024)]),
                    _: 2
                  }, 1024), _createVNode(_component_var_row, null, {
                    default: _withCtx(() => [_createVNode(_component_var_col, {
                      span: 24
                    }, {
                      default: _withCtx(() => [_createElementVNode("div", _hoisted_8, _toDisplayString(item.matchTime), 1)]),
                      _: 2
                    }, 1024)]),
                    _: 2
                  }, 1024)])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 8
                }, {
                  default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_var_row, null, {
                    default: _withCtx(() => [_createVNode(_component_var_col, {
                      span: 24
                    }, {
                      default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString(item.homeTeamAbbName), 1), item.homeRank != null && item.homeRank != '' ? (_openBlock(), _createElementBlock("div", _hoisted_11, "[" + _toDisplayString(item.homeRank) + "]", 1)) : _createCommentVNode("", true)])]),
                      _: 2
                    }, 1024)]),
                    _: 2
                  }, 1024), item.listOdds != null ? (_openBlock(), _createBlock(_component_var_row, {
                    key: 0
                  }, {
                    default: _withCtx(() => [_createVNode(_component_var_col, {
                      span: 24
                    }, {
                      default: _withCtx(() => [_createElementVNode("div", _hoisted_12, [_createVNode(_component_var_row, null, {
                        default: _withCtx(() => [_createVNode(_component_var_col, {
                          span: 8
                        }, {
                          default: _withCtx(() => [_createElementVNode("div", _hoisted_13, _toDisplayString(item.listOdds[oddNum.value].host), 1)]),
                          _: 2
                        }, 1024), _createVNode(_component_var_col, {
                          span: 8
                        }, {
                          default: _withCtx(() => [_createElementVNode("div", _hoisted_14, _toDisplayString(item.listOdds[oddNum.value].handicap), 1)]),
                          _: 2
                        }, 1024), _createVNode(_component_var_col, {
                          span: 8
                        }, {
                          default: _withCtx(() => [_createElementVNode("div", _hoisted_15, _toDisplayString(item.listOdds[oddNum.value].guest), 1)]),
                          _: 2
                        }, 1024)]),
                        _: 2
                      }, 1024)])]),
                      _: 2
                    }, 1024)]),
                    _: 2
                  }, 1024)) : _createCommentVNode("", true)])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 4
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_16, [_createVNode(_component_var_row, null, {
                    default: _withCtx(() => [_createVNode(_component_var_col, {
                      span: 24
                    }, {
                      default: _withCtx(() => [item.sectionsNo999 != '' && item.sectionsNo999 != '无效场次' ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(item.sectionsNo999), 1)) : _createCommentVNode("", true)]),
                      _: 2
                    }, 1024)]),
                    _: 2
                  }, 1024), _createVNode(_component_var_row, null, {
                    default: _withCtx(() => [_createVNode(_component_var_col, {
                      span: 24
                    }, {
                      default: _withCtx(() => [item.sectionsNo999 == '' || item.sectionsNo999 == '无效场次' ? (_openBlock(), _createElementBlock("div", _hoisted_18, "VS")) : _createCommentVNode("", true)]),
                      _: 2
                    }, 1024)]),
                    _: 2
                  }, 1024), _createVNode(_component_var_row, null, {
                    default: _withCtx(() => [_createVNode(_component_var_col, {
                      span: 24
                    }, {
                      default: _withCtx(() => [item.sectionsNo1 != '' && item.sectionsNo999 != '无效场次' ? (_openBlock(), _createElementBlock("div", _hoisted_19, "半场" + _toDisplayString(item.sectionsNo1), 1)) : _createCommentVNode("", true)]),
                      _: 2
                    }, 1024)]),
                    _: 2
                  }, 1024)])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 8
                }, {
                  default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_var_row, null, {
                    default: _withCtx(() => [_createVNode(_component_var_col, {
                      span: 24
                    }, {
                      default: _withCtx(() => [_createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, _toDisplayString(item.awayTeamAbbName), 1), item.awayRank != null && item.awayRank != '' ? (_openBlock(), _createElementBlock("div", _hoisted_22, "[" + _toDisplayString(item.awayRank) + "]", 1)) : _createCommentVNode("", true)])]),
                      _: 2
                    }, 1024)]),
                    _: 2
                  }, 1024), item.listOdds != null ? (_openBlock(), _createBlock(_component_var_row, {
                    key: 0
                  }, {
                    default: _withCtx(() => [_createVNode(_component_var_col, {
                      span: 24
                    }, {
                      default: _withCtx(() => [_createElementVNode("div", _hoisted_23, [_createVNode(_component_var_row, null, {
                        default: _withCtx(() => [_createVNode(_component_var_col, {
                          span: 8
                        }, {
                          default: _withCtx(() => [_createElementVNode("div", _hoisted_24, _toDisplayString(item.listOdds[oddNum.value].big), 1)]),
                          _: 2
                        }, 1024), _createVNode(_component_var_col, {
                          span: 8
                        }, {
                          default: _withCtx(() => [_createElementVNode("div", _hoisted_25, _toDisplayString(item.listOdds[oddNum.value].dw_HANDICAP), 1)]),
                          _: 2
                        }, 1024), _createVNode(_component_var_col, {
                          span: 8
                        }, {
                          default: _withCtx(() => [_createElementVNode("div", _hoisted_26, _toDisplayString(item.listOdds[oddNum.value].small), 1)]),
                          _: 2
                        }, 1024)]),
                        _: 2
                      }, 1024)])]),
                      _: 2
                    }, 1024)]),
                    _: 2
                  }, 1024)) : _createCommentVNode("", true)])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)], 8, _hoisted_3)]),
              icon: _withCtx(() => [_createElementVNode("div", _hoisted_27, _toDisplayString(item.matchStatusName), 1), !item.collapseState ? (_openBlock(), _createElementBlock("div", _hoisted_28, [_createVNode(_component_var_icon, {
                name: "chevron-down"
              })])) : _createCommentVNode("", true), item.collapseState ? (_openBlock(), _createElementBlock("div", _hoisted_29, [_createVNode(_component_var_icon, {
                name: "chevron-up"
              })])) : _createCommentVNode("", true)]),
              default: _withCtx(() => [_createElementVNode("div", _hoisted_30, [_createElementVNode("div", _hoisted_31, [_createVNode(_component_var_row, null, {
                default: _withCtx(() => [_createVNode(_component_var_col, {
                  span: 4
                }, {
                  default: _withCtx(() => [_createVNode(_component_var_menu, {
                    placement: "right",
                    show: item.showMenu,
                    "onUpdate:show": $event => item.showMenu = $event
                  }, {
                    menu: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value, (menu, menuIndex) => {
                      return _openBlock(), _createBlock(_component_var_cell, {
                        key: menuIndex,
                        onClick: $event => closeMenu(item, menu)
                      }, {
                        default: _withCtx(() => [_createTextVNode(_toDisplayString(menu.collectName), 1)]),
                        _: 2
                      }, 1032, ["onClick"]);
                    }), 128))]),
                    default: _withCtx(() => [_createVNode(_component_var_button, {
                      type: "primary"
                    }, {
                      default: _withCtx(() => [_createVNode(_component_var_icon, {
                        name: "star"
                      })]),
                      _: 1
                    })]),
                    _: 2
                  }, 1032, ["show", "onUpdate:show"])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 16
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_32, [_createVNode(_component_var_input, {
                    variant: "outlined",
                    placeholder: "笔记",
                    clearable: "",
                    size: "small",
                    modelValue: item.note.content,
                    "onUpdate:modelValue": $event => item.note.content = $event
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 4
                }, {
                  default: _withCtx(() => [_createVNode(_component_var_button, {
                    type: "info",
                    onClick: $event => noteSave(item.matchId, item.note)
                  }, {
                    default: _withCtx(() => [_createTextVNode("保存")]),
                    _: 2
                  }, 1032, ["onClick"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)]), fullUser.value && item.matchStatusName != '待开售' || item.matchStatusName == '已完成' ? (_openBlock(), _createBlock(_component_var_row, {
                key: 0,
                gutter: [10, 10]
              }, {
                default: _withCtx(() => [_createVNode(_component_var_col, {
                  span: 6
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_33, [_createVNode(_component_var_button, {
                    type: "danger",
                    onClick: $event => clickHeat(item.matchId)
                  }, {
                    default: _withCtx(() => [_createTextVNode("热度")]),
                    _: 2
                  }, 1032, ["onClick"])])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 6
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_34, [_createVNode(_component_var_button, {
                    color: "#FF6633",
                    "text-color": "#fff",
                    onClick: $event => clickTtg(item.matchId)
                  }, {
                    default: _withCtx(() => [_createTextVNode("进球")]),
                    _: 2
                  }, 1032, ["onClick"])])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 6
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_35, [_createVNode(_component_var_button, {
                    color: "#00CC00",
                    "text-color": "#fff",
                    onClick: $event => clickMoney(item.matchId)
                  }, {
                    default: _withCtx(() => [_createTextVNode("资金")]),
                    _: 2
                  }, 1032, ["onClick"])])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 6
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_36, [_createVNode(_component_var_button, {
                    color: "#CC3299",
                    "text-color": "#fff",
                    onClick: $event => clickHandicap(item.oddId)
                  }, {
                    default: _withCtx(() => [_createTextVNode("盘口")]),
                    _: 2
                  }, 1032, ["onClick"])])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 6
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_37, [_createVNode(_component_var_button, {
                    type: "success",
                    onClick: $event => clickList(item.matchId)
                  }, {
                    default: _withCtx(() => [_createTextVNode("历史")]),
                    _: 2
                  }, 1032, ["onClick"])])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 6
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_38, [_createVNode(_component_var_button, {
                    type: "primary",
                    onClick: $event => clickInfo(item.matchId)
                  }, {
                    default: _withCtx(() => [_createTextVNode("对比")]),
                    _: 2
                  }, 1032, ["onClick"])])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 6
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_39, [_createVNode(_component_var_button, {
                    type: "warning",
                    onClick: $event => clickBS(item.matchId)
                  }, {
                    default: _withCtx(() => [_createTextVNode("能力")]),
                    _: 2
                  }, 1032, ["onClick"])])]),
                  _: 2
                }, 1024), _createVNode(_component_var_col, {
                  span: 6
                }, {
                  default: _withCtx(() => [_createElementVNode("div", _hoisted_40, [_createVNode(_component_var_button, {
                    color: "#7093DB",
                    "text-color": "#fff",
                    onClick: $event => clickKLine(item.oddId, item.matchId)
                  }, {
                    default: _withCtx(() => [_createTextVNode("时间")]),
                    _: 2
                  }, 1032, ["onClick"])])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)) : _createCommentVNode("", true)])]),
              _: 2
            }, 1032, ["name"])]),
            _: 2
          }, 1032, ["modelValue"]);
        }), 128))]),
        _: 1
      }, 8, ["loading"]), _createVNode(_component_var_fab, {
        type: _ctx.primary,
        bottom: 100
      }, {
        default: _withCtx(() => [_createVNode(_component_var_button, {
          type: "info",
          onClick: refreshClick,
          "icon-container": ""
        }, {
          default: _withCtx(() => [_createVNode(_component_var_icon, {
            name: "refresh",
            size: 30
          })]),
          _: 1
        }), _createVNode(_component_var_button, {
          type: "primary",
          onClick: _cache[5] || (_cache[5] = $event => changeOdds(1)),
          "icon-container": ""
        }, {
          default: _withCtx(() => [_createTextVNode(" 365 ")]),
          _: 1
        }), _createVNode(_component_var_button, {
          type: "warning",
          onClick: _cache[6] || (_cache[6] = $event => changeOdds(0)),
          "icon-container": ""
        }, {
          default: _withCtx(() => [_createTextVNode(" 黄瓜 ")]),
          _: 1
        })]),
        _: 1
      }, 8, ["type"])], 512);
    };
  }
};